// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invoice-container {
    
    margin: auto;
    
    border: 1px solid #eee;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
 

    background-color: #fff;
  }

  .field{text-align: left;}
  
  .invoice-header, .invoice-footer, .invoice-table, .invoice-summary {
    margin-bottom: 20px;
  }
  
  .invoice-table th, .invoice-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .invoice-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  /* Additional styling for layout, fonts, and colors */
  `, "",{"version":3,"sources":["webpack://./src/Invoice.css"],"names":[],"mappings":"AAAA;;IAEI,YAAY;;IAEZ,sBAAsB;IACtB,wCAAwC;;;IAGxC,sBAAsB;EACxB;;EAEA,OAAO,gBAAgB,CAAC;;EAExB;IACE,mBAAmB;EACrB;;EAEA;IACE,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,yBAAyB;EAC3B;;EAEA,qDAAqD","sourcesContent":[".invoice-container {\r\n    \r\n    margin: auto;\r\n    \r\n    border: 1px solid #eee;\r\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);\r\n \r\n\r\n    background-color: #fff;\r\n  }\r\n\r\n  .field{text-align: left;}\r\n  \r\n  .invoice-header, .invoice-footer, .invoice-table, .invoice-summary {\r\n    margin-bottom: 20px;\r\n  }\r\n  \r\n  .invoice-table th, .invoice-table td {\r\n    border: 1px solid #ddd;\r\n    padding: 8px;\r\n    text-align: left;\r\n  }\r\n  \r\n  .invoice-table {\r\n    width: 100%;\r\n    border-collapse: collapse;\r\n  }\r\n  \r\n  /* Additional styling for layout, fonts, and colors */\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
