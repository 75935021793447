import React from 'react';

const InvoiceFooter = ({ footerData }) => {
  return (


<div class="invoice-footer mt-4 print-footer">
<div class="print:hidden w-1/3 ">
  <div class="flex justify-end">
  <button class="text-slate-50 px-4 py-1 bg-slate-600 rounded-lg text-lg" onClick={() => window.print()} type='button'>Print</button>
  </div>
</div>

<img alt="Organization Logo" class= "hidden print:block float-right" src="/static/address.PNG" />
</div>


  );
};

export default InvoiceFooter;
