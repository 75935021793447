import React from 'react';
//import './Beneficiary.css'; // Make sure to create and import corresponding CSS

const Beneficiary = () => {
  return (
    <div className="beneficiary bg-sky-50 border rounded-md p-4">
      <div className="title pb-4 text-slate-700 font-medium text-left">BENEFICIARY / المستفيد</div>
      <div className="details text-slate-500 text-sm text-left">
        <div>SILVER PRIZM Trading ESTABLISHMENT <br/> مؤسسة المنشور الفضي التجارية</div>
        <div ><b>Bank / البنك: </b>Saudi Awwal Bank</div>
        <div><b>Branch / الفرع: </b>Fanteer Branch Jubail, KSA</div>
        <div><b>IBAN Number / رقم الآيبان: </b>SA1045000000836028795001</div>
        
        <div><b>Account Number / رقم الحساب :</b> <span dir="ltr">836028795001 </span>  </div> 
        <div><b>VAT Number / رقم التسجيل الضريبي: </b> <span dir="ltr">300601075900003 </span> </div>
      </div>
    </div>
  );
};

export default Beneficiary;
