import React, { useState, useEffect, useRef } from 'react';



const InvoiceBody = ({ bodyData, onTotalsChange }) => {
  const initialItems = Array.isArray(bodyData) ? bodyData : [];
  const [items, setItems] = useState(initialItems);
  const [newItem, setNewItem] = useState({ description: '', price: '', quantity: 1, total: 0 });
  const [discount, setDiscount] = useState(0);
  const [totals, setTotals] = useState({ totalCost: 0, discountAmount: 0, totalAfterDiscount: 0, vatAmount: 0, grandTotal: 0 });
  //error handling
  const descriptionRef = useRef(null);
  const [hasError, setHasError] = useState(false);

  
  // After calculating totals
  useEffect(() => {
    // Call the callback function with the calculated totals
    onTotalsChange({ total: totals.grandTotal, vat: totals.vatAmount });
  }, [totals.grandTotal, totals.vatAmount, onTotalsChange]);
  
  
  useEffect(() => {
    calculateTotals();
  }, [items, discount]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.ctrlKey && e.key === 'a') {
        e.preventDefault();
        handleAddItem();
      }
      else if (e.key === 'Enter') {
        e.preventDefault(); // Optional: Prevent the default Enter key action if necessary
        // Define what should happen when Enter is pressed
        // For example, you can also call handleAddItem() or any other function
        handleAddItem();
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [newItem, items]);

  const handleInputChange = (e, field) => {
    const value = field === 'price' || field === 'quantity' ? parseFloat(e.target.value) || 0 : e.target.value;
    setNewItem(prev => ({
      ...prev,
      [field]: value,
      total: field === 'price' || field === 'quantity' ? value * (field === 'price' ? prev.quantity : prev.price) : prev.total
    }));
  };

  const handleAddItem = () => {
    if (!newItem.description || !newItem.price || !newItem.quantity){
      setHasError(true);
      descriptionRef.current.focus();
      return;
    } 
    setItems(prevItems => [...prevItems, { ...newItem, id: prevItems.length + 1 }]);
    setNewItem({ description: '', price: '', quantity: 1, total: 0 });
    setHasError(false); // Reset the error state
    descriptionRef.current.focus();
    //invoiceTotals = {'grandTotal': totals.grandTotal, 'vat' : totals.vatAmount }  
    //console.log('item is added');
    //generateQRCode();

  };

  const handleDeleteItem = id => setItems(items.filter(item => item.id !== id));

  const handleChange = (event, index, fieldName) => {
    const value = parseFloat(event.target.value) || 0;
    const updatedItems = items.map((item, idx) => idx === index ? { ...item, [fieldName]: value, total: fieldName === 'price' || fieldName === 'quantity' ? value * (fieldName === 'price' ? item.quantity : item.price) : item.total } : item);
    setItems(updatedItems);
  };

  const handleDiscountChange = e => {
    setDiscount(e.target.value);
  };

  const calculateTotals = () => {
    const totalCost = items.reduce((acc, item) => acc + item.total, 0);
    const discountAmount = totalCost * (parseFloat(discount) || 0) / 100;
    const totalAfterDiscount = totalCost - discountAmount;
    const vatAmount = totalAfterDiscount * 0.15; // Assuming VAT is 15%
    const grandTotal = totalAfterDiscount + vatAmount;

    setTotals({ totalCost, discountAmount, totalAfterDiscount, vatAmount, grandTotal });
  };

  const handleEditItem = (id) => {
    setItems(items.map(item => item.id === id ? { ...item, isEditing: true } : item));
  };

  const handleSaveItem = (id) => {
    setItems(items.map(item => item.id === id ? { ...item, isEditing: false } : item));
  };

  return (
    <div className='invoice-body mt-4'>
      
    <table className="items-table w-full border border-dashed custom-border">
    <thead className="border rounded-xl bg-sky-50 text-slate-700 text-left custom-border">
        <tr>
          <th scope="col" className="font-light text-sm text-gray-500 px-2  text-left w-2/3 print:text-sm">التفاصيل<br />Description</th>
          <th scope="col" className="font-light text-sm text-gray-500 px-2  text-right  w-1/12">السعر<br />Price</th>
          <th scope="col" className="font-light text-sm text-gray-500 px-2  text-right  w-1/12" >كمية<br />Quantity</th>
          <th scope="col" className="font-light text-sm text-gray-500 px-2  text-right w-1/6 pl-1 ">التكلفة الإجمالية<br />
            TOTAL COST<span className="font-medium">(SAR)</span>
          </th>
          <th scope="col" className="font-light text-sm text-gray-500 px-2  text-left print:hidden'"></th>
        </tr>
      </thead>
        
      <tbody className="">
      <tr className="border-b print:hidden">
            <td className="px-2 pt-3 block grow text-left"><div>
              <textarea ref={descriptionRef} value={newItem.description} onChange={(e) => handleInputChange(e, 'description')} className="input-text-area text-slate-500 w-full h-7 px-2 py-1 overflow-hidden mb-0 border rounded-lg" placeholder="Enter Item name/Description" style={{ height: '34px' }} ></textarea></div></td>
            <td className="px-2 py-3 text-right"><div>
              <input value={newItem.price}  onChange={(e) => handleInputChange(e, 'price')}  className="text-slate-500 w-full h-9 p-2 border rounded-lg text-right" placeholder="Price" type="number"  /></div></td>
            <td className="px-2 py-3 text-right"><div>
              <input value={newItem.quantity} onChange={(e) => handleInputChange(e, 'quantity')} className="text-slate-500 w-full h-9 p-2 border rounded-lg text-left" placeholder="Quantity" type="number"  /></div></td>
            
            <td className="px-2 py-3 text-slate-500 text-right pl-1"> <span name="newItemTotal">{newItem.total.toFixed(2)} </span> </td>
            
            <td className="px-2 py-3">
              
              <svg tabIndex="0" name="AddButton"  onClick={() => {
                                                        handleAddItem(newItem);
                                                        setNewItem({ description: '', price: 0, quantity: 1, total: 0 }); // Reset the newItem state
                                                     }}
    
                    xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-slate-500 hover:cursor-pointer hover:text-slate-900" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z">
                      </path>
              </svg>
            </td>
            
        </tr>              
        
        {items.map((item, index) => (
          <tr key={item.id}>
             {item.isEditing ? (
              <>
            <td className="px-2 pt-3 block grow">
              <div><textarea className="text-slate-500 w-full h-7 px-2 py-1 overflow-hidden mb-0 border rounded-lg text-left"  style={{ height: '34px' }}  value={item.description}  onChange={e => handleChange(e, index, 'description')}></textarea></div>
            </td>
            <td className="px-2 py-3"><div><input className="text-slate-500 w-full h-9 p-2 border rounded-lg "  type="number" value={item.price} onChange={e => handleChange(e, index, 'price')} /></div></td>
            <td className="px-2 py-3"><div><input className="text-slate-500 w-full h-9 p-2 border rounded-lg "  type="number" value={item.quantity} onChange={e => handleChange(e, index, 'quantity')} /></div></td>
            <td className="px-2 py-3 text-slate-500">
            <div> <span>{item.total.toFixed(2)}</span> </div>
           </td>
            <td className="px-2 py-1 print:hidden">
              <div class="flex">
              <svg name='saveButton' onClick={() => handleSaveItem(item.id) } xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-400 hover:cursor-pointer hover:text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
             </div>
            </td>
            </>
             )
             :
             (
              <>
              <td className="px-2 pt-3 block grow text-left">
              <div> <span>{item.description}</span> </div>
            </td>
            <td className="px-2 py-3 text-right">
                <div> <span>{item.price}</span> </div>
              </td>
            <td className="px-2 py-3 text-right">
                <div> <span>{item.quantity}</span> </div>
            </td>
            
            <td className="px-2 py-3 text-right">
                <div> <span>{item.total.toFixed(2)}</span> </div>
            </td>
            
            <td className="px-2 py-1 print:hidden">
              <div class="flex">
              <svg name='editButton' onClick={() => handleEditItem(item.id)} xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mx-2 hover:text-slate-900 hover:cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path></svg>
              <svg name='deleteButton' onClick={() => handleDeleteItem(item.id)} xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-500 hover:text-red-900 hover:cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
              </div>
            </td>
            </>
             )}
          </tr>
        ))}
      </tbody>
    </table>

    <div className="totals grid grid grid-cols-3 gap-4 py-4 text-right">
    
      <div className="text-slate-500 text-sm  font-bold  px-2 col-span-2">
        <div className="border-b-2 flex flex-row">
          <div className="px-2 basis-4/5" >TOTAL COST ( التكلفة الإجمالية )</div>
          <div className="px-2 basis-1/5" name="totalCost">SAR  {totals.totalCost.toFixed(2)} </div></div>
          <div className="border-b-2 flex flex-row">
            <div className="px-2 basis-4/5">SPECIAL DISCOUNT % ( % خصم خاص )</div>
            <div className="basis-1/5 print:hidden">
              <div><input name="discount" className="text-slate-500 w-full text-sm px-2 py-1 border rounded-lg print:border-0" placeholder="" type="number" value={discount} onChange={handleDiscountChange} /></div>
              </div>
              <div name="discountLabel"  className="px-2 basis-1/5 hidden print:block">{discount}%</div>
            </div>
            <div className="border-b-2 flex flex-row">
              <div className="px-2 basis-4/5">DISCOUNTED AMOUNT ( المبلغ المخصوم )</div>
              <div className="px-2 basis-1/5" name="discountAmount">SAR {totals.discountAmount.toFixed(2)}</div></div>
              <div className="border-b-2 flex flex-row">
              <div className="px-2 basis-4/5">TOTAL AFTER DISCOUNT ( إجمالي بعد الخصم )</div>
              <div className="px-2 basis-1/5" name="totalAfterDiscount">SAR {totals.totalAfterDiscount.toFixed(2)}</div></div>
              <div className="border-b-2 flex flex-row">
                  <div className="px-2 basis-4/5">15% VAT AMOUNT (15% ضريبة)</div>
                  <div className="px-2 basis-1/5" name="vatAmount">SAR {totals.vatAmount.toFixed(2)}</div>
              </div>

              <div className="border-b-2 flex flex-row">
                  <div className="px-2 basis-4/5">Grand total (المجموع الكلي مع ضريبة القيمة المضافة)</div>
                  <div className="px-2 basis-1/5" name="grandTotal">SAR {totals.grandTotal.toFixed(2)}</div>
              </div>

      </div>
            
         
          
      </div>

          <div className="mt-10 px-2 text-slate-500 text-sm text-left ">
            Make the cheque payable to “Silver Prizm Trading Establishment” or Silver Prizm Bank Account (IBAN: SA1045000000836028795001). 
            If you have any questions concerning this invoice, contact us at info@cynosureworldwide.com.
          </div>




    
  </div>

  );
};

export default InvoiceBody;

