import react, { useState, useEffect } from 'react';
import InvoiceHeader from './InvoiceHeader';
import InvoiceBody from './InvoiceBody';
import InvoiceFooter from './InvoiceFooter';
import './Invoice.css';
import { Invoice } from '@axenda/zatca';




const InvoiceComponent = ({ invoiceDataProp }) => {
  const [qrcodeImageData, setQrcodeImageData] = useState('');
 
  const [invoiceTotals, setInvoiceTotals] = useState({ total: 0, vat: 0 });
  const [sellerInfo, setSellerInfo] = useState({ sellerName: '', vatRegistrationNumber: '' });


  useEffect(() => {
    // Assuming sellerInfo and invoiceTotals are set
    generateQRCode();
  }, [sellerInfo, invoiceTotals]);
  
  

  const generateQRCode = async () => {
    console.log('Generating QR code');

    sellerInfo.sellerNam = 'SILVER PRIZM Trading ESTABLISHMENT';
    sellerInfo.vatRegistrationNumber = '300601075900003';
    const zatcaInvoice = new Invoice({
      sellerName: sellerInfo.sellerName,
      vatRegistrationNumber: sellerInfo.vatRegistrationNumber,
      invoiceTimestamp: new Date().toISOString(),
      invoiceTotal: invoiceTotals.total.toString(),
      invoiceVatTotal: invoiceTotals.vat.toString(),
    });

    try {
      const imageData = await zatcaInvoice.render();
      setQrcodeImageData(imageData);
    } catch (error) {
      console.error('Error generating QR code:', error);
    }
  };


  return (
    <div className="invoice-container  App w-full  p-2 print:break-before-all print:mx-0 print:border-0">
      <div className="container px-4 border rounded-md print:border-0"> 
      <InvoiceHeader headerData={invoiceDataProp.header} qrcodeImageData={qrcodeImageData} onSellerInfoChange={setSellerInfo} />
      <InvoiceBody bodyData={invoiceDataProp.body}  onTotalsChange={setInvoiceTotals} />
      <InvoiceFooter footerData={invoiceDataProp.footer} />
      </div>
    </div>
  );
};

export default InvoiceComponent;
