// src/App.js
import React from 'react';
import './App.css';
import InvoiceComponent from './Invoice';
window.Buffer = window.Buffer || require("buffer").Buffer;

function App() {


  // Sample invoice data
const invoiceData = {
  subject:'This is inovice subject'
  ,
  header: {
    invoiceNumber: 'INV-001',
    date: '2024-01-01',
    referenceNumber: 'REF-001'
  },
  body: {
    customerName: 'John Doe',
    customerAddress: '123 Main St, Anytown, USA',
    items: [
      { id: 1, description: 'Item 1', quantity: 2, unitPrice: 100, total: 200 },
      { id: 2, description: 'Item 2', quantity: 1, unitPrice: 200, total: 200 }
    ]
  },
  footer: {
    subtotal: 400,
    tax: 40,
    total: 440,
    notes: 'Thank you for your business.'
  }
};



  return (
    <div className="App">
      <header className="App w-full lg:w-9/12 lg:pl-72  print:break-before-all print:mx-0 print:border-0">
        <InvoiceComponent invoiceDataProp = {invoiceData}/>
      </header>
    </div>
  );
}

export default App;
