import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const ToAddress = () => {
  const [isEditable, setIsEditable] = useState(true);
  const [fields, setFields] = useState({
    customerName: '',
    street: '',
    cityArea: '',
    country: '',
    vatNumber: '',
  });
  const [searchResults, setSearchResults] = useState([]);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:9000";

  const toggleEditable = () => setIsEditable(!isEditable);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields(prevFields => ({
      ...prevFields,
      [name]: value,
    }));

    if (name === 'customerName' && value.length > 3) {
      fetchCustomers(value);
    }
  };

  const fetchCustomers = useCallback(async (customerName) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/customer/search?name=${customerName}`);
      setSearchResults(response.data);
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
  }, [API_BASE_URL]);

  const handleSelectCustomer = (customer) => {
    setFields({
      customerName: customer.customerName,
      street: customer.street,
      cityArea: customer.cityArea,
      country: customer.country,
      vatNumber: customer.vatNumber,
    });
    setSearchResults([]); // Clear search results after selection
  };

  return (
    <div className="to-address border bg-sky-50 rounded-md p-4 relative">
      <div className="header pb-4 text-slate-700 font-medium flex flex-row">
        <div className="title basis-4/5 text-left">TO / إلى</div>
        <div className="basis-1/5 print:hidden toggle-edit" onClick={toggleEditable}>
          {isEditable ? (
            <svg className="h-6 w-6 float-right hover:text-slate-400 hover:cursor-pointer" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-400 hover:cursor-pointer float-right hover:text-green-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
            </svg>
          )}
        </div>
      </div>

      <div className="fields">
        {Object.entries(fields).map(([key, value]) => (
          <div key={key} className="mb-1 field">
            {isEditable ? (
              key === 'customerName' ? (
                <div className="relative">
                  <input
                    type="text"
                    name={key}
                    className="text-slate-500 w-full text-sm px-2 py-1 border rounded-lg"
                    placeholder={key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                    value={value}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                  {fields.customerName.length > 3 && searchResults.length > 0 && (
                    <div className="search-results absolute left-0 right-0 mt-1 max-h-60 overflow-auto bg-white shadow-md rounded-md z-10">
                      {searchResults.map((customer, index) => (
                        <div
                          key={index}
                          className="search-result-item p-2 hover:bg-sky-100 cursor-pointer"
                          onClick={() => handleSelectCustomer(customer)}
                        >
                          {customer.customerName}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                <input
                  type="text"
                  name={key}
                  className="text-slate-500 w-full text-sm px-2 py-1 border rounded-lg"
                  placeholder={key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g,' $1').trim()} // Converts camelCase to Title Case
                  value={value}
                  onChange={handleChange}
                  autoComplete="off"
                />
              )
            ) : (
              <label className="block">
                
                <span className="text-gray-600 ml-2">{value}</span>
              </label>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ToAddress;
