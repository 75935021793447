import React, { useState, useEffect } from "react";
import ToAddress from "./ToAddress";
import Beneficiary from "./Beneficiary";

const InvoiceHeader = (qrcodeImageData ) => {
  // Initialize state for header data

  const [isPrinting, setIsPrinting] = useState(false);

  const [headerData, setHeaderData] = useState({
    invoiceNumber: "INV-001",
    date: "2024-03-14", // Example initial date
    referenceNumber: "",
    subject: "",
  });

  // Function to handle changes in input fields
  const handleChange = (field, value) => {
    setHeaderData((prevHeaderData) => ({
      ...prevHeaderData,
      [field]: value,
    }));
  };

  // Function to format the current date as YYYY-MM-DD
  const formatDate = (date) => {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const [dateValue, setDateValue] = useState(formatDate(new Date())); // Set current date as default

  useEffect(() => {
    const handleBeforePrint = () => setIsPrinting(true);
    const handleAfterPrint = () => setIsPrinting(false);

    window.addEventListener("beforeprint", handleBeforePrint);
    window.addEventListener("afterprint", handleAfterPrint);

    return () => {
      window.removeEventListener("beforeprint", handleBeforePrint);
      window.removeEventListener("afterprint", handleAfterPrint);
    };
  }, []);

  const handleDateChange = (event) => {
    setDateValue(event.target.value);
    handleChange("date", event.target.value);
  };

 
  return (
    
    <div className="">
      <div class="flex">
        <div class="w-1/2 bg-gray-200 p-1">
          <div className="inline-block align-middle text-left">
            <h1 className="align-middle uppercase text-2xl font-medium text-slate-500">
              TAX INVOICE
            </h1>

            <div className="field">
              <label className="text-slate-500 text-sm">
                INVOICE No. (رقم الفاتورة):
              </label>

              {isPrinting ? (
                <span className="text-slate-500">
                  {headerData.invoiceNumber}
                </span>
              ) : (
                <input
                  className="text-slate-500  h-9 p-2 border rounded-lg text-sm"
                  value={headerData.invoiceNumber}
                  onChange={(e) =>
                    handleChange("invoiceNumber", e.target.value)
                  }
                />
              )}
            </div>

            <div className="field">
              <label className="text-slate-500 text-sm">DATE. (تاريخ):</label>
              {isPrinting ? (
                <span className="text-slate-500">{dateValue}</span>
              ) : (
                <input
                  type="date"
                  className="text-slate-500  border rounded-lg text-sm"
                  value={headerData.date}
                  onChange={(e) => handleDateChange(e)}
                />
              )}
            </div>

            <div className="field">
              <label className="text-slate-500 text-sm">
                REFERENCE No. (رقم المرجع):
              </label>

              {isPrinting ? (
                <span className="text-slate-500">
                  {headerData.referenceNumber}
                </span>
              ) : (
                <input
                  type="text"
                  className="text-slate-500   border rounded-lg text-sm"
                  value={headerData.referenceNumber}
                  onChange={(e) =>
                    handleChange("referenceNumber", e.target.value)
                  }
                />
              )}
            </div>
          </div>
        </div>
        <div class="w-1/6 bg-gray-200 p-1">
         
         {qrcodeImageData.qrcodeImageData && <img className="h-16 w-16" src={qrcodeImageData.qrcodeImageData} alt="QR Code" />}
          
        </div>
        <div class="w-1/2 bg-gray-200 p-1">
          <img
            alt="Organization Logo"
            className="float-right"
            src="/static/silver-prism-logo.jpeg"
            style={{ width: "80%" }}
          />
        </div>
      </div>
      <div class="mt-10">
        <div class="font-bold">
          <label class="text-slate-500 ">SUBJECT (الموضوع):</label>
          <input
            type="text"
            class="text-slate-500  border rounded-lg w-1/2 font-bold"
            maxLength={400}
            value={headerData.subject}
            onChange={(e) => handleChange("subject", e.target.value)}
          />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 print:grid-cols-2 gap-2 mt-4 invoice-to-beneficiary-container">
        <div className="invoice-to">
          <div>
            <ToAddress />
          </div>
        </div>

        <div className="invoice-beneficiary">
          <Beneficiary />
        </div>
      </div>
    </div>
  );
};

export default InvoiceHeader;
